import clsx from 'clsx';
import Typography from '@ui/Typography';
import Image from '@ui/Image';
import StaticIcon from '@ui/StaticIcon';
import { t } from '@lib/i18n';
import Button from '@ui/Button';

const colorsVariants = {
  primary: 'bg-primary100/50',
  teal: 'bg-teal100/50',
  orange: 'bg-orange100/50',
  cyan: 'bg-cyan100/50',
  pink: 'bg-pink100/50',
  yellow: 'bg-yellow100/50',
  purple: 'bg-purple100/50',
};

const cardContainerStyles = 'group relative block w-[416px] rounded-lg bg-white p-1 pb-1.5 shadow-[6px_6px_40px_0px_#ECF2FF] transition duration-300 lg-down:w-[327px] md:hover:translate-y-[-24px] lg:hover:translate-y-[-24px]';

export const WebinarCard = ({
  item: {
    title, subtitle, name, icon_text, color, position, link, photo, bg_image,
  }, 
}) => {
  return (
    <a href={link} target="_blank" className={cardContainerStyles}>
      <div className={clsx(
        'relative rounded-t-lg rounded-b-2xl',
        colorsVariants[color],
      )}
      >
        {bg_image && <Image src={bg_image} alt="" fullWidth="false" className="absolute h-full" />}
        <Image src="/images/webinars/bg-waves.svg" alt="" className="absolute bottom-0 right-0 mix-blend-overlay" />
        <div className="flex h-[184px] flex-col justify-between p-2 pb-0 pr-0">
          <div className="flex flex-col gap-1">
            <Typography className="z-10 max-w-[240px] text-2xl font-medium leading-[25px] lg-down:max-w-[160px] lg-down:text-2lg lg-down:leading-[21px]">
              {title}
            </Typography>
            <Typography className="z-10 max-w-[240px] text-base font-normal leading-[20px] lg-down:max-w-[160px] lg-down:text-xs lg-down:leading-1">
              {subtitle}
            </Typography>
          </div>
          {name && (
            <div className={clsx(
              'w-fit rounded-lg bg-white p-1 shadow-[0px_0px_4px_0px_#3878FF14]',
              'z-10 mr-12 mb-2 self-end',
            )}
            >
              <Typography className="text-[11px] font-semibold leading-[15px]">{name}</Typography>
              <Typography className="text-[11px] leading-[15px]">{position}</Typography>
            </div>
          )}
          {photo && (
            <Image src={photo} alt="author photo" className="absolute inset-y-0 right-0 h-full" />
          )}
        </div>
      </div>
      <div className="mt-1.5 ml-2 flex h-[20px] gap-1">
        <StaticIcon name="play" />
        <Typography className="text-2xs">{icon_text}</Typography>
      </div>
    </a>
  );
};

export const WebinarAnnouncementCard = ({ item: { date, title, link } }) => {
  const onButtonClick = () => {
    window.open(link, '_blank');
  };

  return (
    <div className={clsx(cardContainerStyles, 'relative')}>
      <div className="h-full after:absolute after:inset-0 after:bg-[url('/images/webinars/bg-card.svg')] after:bg-no-repeat after:blur-lg after:content-['']">
        <div className="rounded-lg border border-[#E2EBFF] bg-[linear-gradient(180deg,#FFFFFF_0%,rgba(255,255,255,0.817367)_23.29%,rgba(255,255,255,0.563662)_58.48%,rgba(255,255,255,0)_100%)] p-2">
          <div className="flex items-center gap-1.5">
            <div className="w-4 rounded-lg bg-tertiary100 p-0.5 shadow-[2px_4px_12px_0px_#E7EEFB]">
              <StaticIcon name="calendar" />
            </div>
            <Typography className="font-medium">{date}</Typography>
          </div>
          <Typography variant="header4" className="mt-5 font-medium leading-[25px]">{title}</Typography>
          <Button onClick={onButtonClick} className="mt-3">{t('webinars.registration')}</Button>
        </div>
      </div>
    </div>
  );
};
